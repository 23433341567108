const button = document.querySelector("#ctas button");
const formWrap = document.getElementById('contact-form-wrap');
const form = document.getElementById('contact-form');

button.addEventListener('click', function (e) {
  formWrap.classList.add("animate");
  button.remove();
})

form.addEventListener('submit', function (e) {
  e.preventDefault();
  let data = new FormData(e.target);
  fetch(e.target.action + e.target.getAttribute("data-emailID"), {
    method: "POST",
    headers: {
      'Accept': 'application/json',
    },
    body: data
  })
    .then((result) => {
      if (result.status !== 200) {
        throw new Error("Bad Server Response");
      }
      return result.json();
    })
    .then((response) => {
      if(response?.sent){
        console.log('EMAIL SENT SUCCESSFULLY!');
        // formWrap.classList.remove("animate");
        formWrap.innerHTML= '<p>Message Sent</p>';
        // form.reset();
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return false;
})
